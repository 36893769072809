function imageLightbox() {
	var $ = jQuery,
	_self = this;

	// Takes a string and returns whether
	function filterExtensions(str) {
		/**
		 * Define file extensions of allowed image file types in WordPress.
		 * Also add WebP in for good measure.
		 * https://codex.wordpress.org/Function_Reference/get_allowed_mime_types
		 */
		var patt = /\.(jpg|jpeg|gif|png|bmp|tif|tiff|webp)$/gi;
		return patt.test(str);
	}

	// Select all post images
	var $postImages = $('.hentry > section a:has(img[class*="wp-image"])');

	// Select post galleries
	var $galleries = $('.gallery:not(.child-pages) .gallery-icon a');

	// Merge into one object, then filter to those that link to an image
	var	$imageItems = $postImages.add( $galleries ).filter( function() {
		return filterExtensions( $(this).prop('href') )
	} );

	var magnificOptions = {
		type: 'image',
		mainClass: 'mfp-with-zoom',
		autoFocusLast: false,
		removalDelay: 300,
		overflowY: 'hidden',
		image: {
			titleSrc: function(item) {
				return item.el.next('figcaption').text() || item.el.find('img').attr('alt') || '';
			},
			preload: [1,2]
		},
		gallery: {
			enabled: true
		}
	};

	$imageItems.magnificPopup(magnificOptions);
}


var masonryGalleries = function() {
	var $ = jQuery;

	// Specify where packages should be loaded from
	var src = {
		imagesloaded: 'https://npmcdn.com/imagesloaded@4.1/imagesloaded.pkgd.js',
		masonry: 			'https://npmcdn.com/masonry-layout@4.0/dist/masonry.pkgd.js'
	}

	var galleries = $('.hentry > section .gallery:not(.child-pages)');

	// if there are no galleries, do nothing
	if (galleries.length === 0) return;

	// Promise - load both scripts
	$.when(
		$.getScript(src.imagesloaded),
		$.getScript(src.masonry)
	).done(function() { // Execute when both have been downloaded

		// initialise Masonry
		var g = galleries.masonry({
			// gallery items
      itemSelector: '.gallery-item',
			// use the width of the items
      columnWidth: '.gallery-item',
			// widths are defined in %
      percentPosition: true,
			// disable animations - this gets messy during image load
      transitionDuration: 0
    });

		// as each image loads, fit it into the masonry
		g.imagesLoaded().progress(function() {
      g.masonry('layout');
    });

		/*
		when all images are loaded, turn animations back on
		this will animate them when the page is resized
		e.g. when a mobile device is rotated
		g.imagesLoaded(function() {
			g.data('masonry').options.transitionDuration = '0.3s';
		});
		*/

	})
};
