/*********
FUNCTIONS
*********/

// Popup windows - centered
var popupCenter = function(url, title, width, height) {
	var w = window,
			de = document.documentElement,

  // Fixes dual-screen position - Most browsers : Firefox
  		dualScreenLeft = w.screenLeft != undefined ? w.screenLeft : screen.left,
	  	dualScreenTop = w.screenTop != undefined ? w.screenTop : screen.top,

	// get viewport dimensions
		  vw = w.innerWidth ? w.innerWidth : de.clientWidth ? de.clientWidth : screen.width,
  		vh = w.innerHeight ? w.innerHeight : de.clientHeight ? de.clientHeight : screen.height,

	// calculate window position, to center it on screen
  		left = ((vw / 2) - (width / 2)) + dualScreenLeft,
		  top = ((vh / 2) - (height / 2)) + dualScreenTop,

  		newWindow = w.open(url, title, 'scrollbars=yes, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);

  // Puts focus on the newWindow
  if (newWindow)
    newWindow.focus();
} // end popupCenter()
