// Start main code here - dom ready event
jQuery(function($) {

	$('html').removeClass('no-js').addClass('js');

	// load Gravatars
	loadGravatars();

	// setup the hamburger menu
	hamburgerMenu();

	// make menus keyboard-friendly
	accessibleHoverMenus($('.nav'));

	// get the aspect ratio of our oEmbeds and modify the CSS accordingly
	$('.oembed-container iframe').each(function() {
		var el = $(this);
		var w = el.prop('width');
		var h = el.prop('height');

		el.parent().css('padding-bottom', h/w*100 + '%')
	});

	// Load Contact Form 7 Plugin
	/*
	if ($('.wpcf7-form').length > 0) {

		$.when(
			$.getScript(blogInfo('pluginDir') + 'contact-form-7/includes/js/jquery.form.min.js').fail(function(xhr,error,exception) {
					console.log(exception);
			}),
			$.getScript(blogInfo('pluginDir') + 'contact-form-7/includes/js/scripts.js').fail(function(xhr,error,exception) {
					console.log(exception);
			})
		).done(function() {})
	}
	*/

	// add placeholder text
	$('.wpcf7-free-text').prop('placeholder', 'Please specify...');

	// QUESTION: What is this?
  (function() {
      var ev = new jQuery.Event('style'),
          orig = jQuery.fn.css;
      jQuery.fn.css = function() {
          jQuery(this).trigger(ev);
          return orig.apply(this, arguments);
      }
  })();

  setTimeout(function () {
    jQuery('img.ajax-loader').bind('style', function (e) {

      var style = jQuery(this).attr('style');
      console.log("style: " + style);
      if (style) {
        if(style.indexOf("hidden") > 1) {
          // enable button
          //console.log("Disable  button");
          jQuery('input.wpcf7-submit').attr('disabled','disabled');
        } else {
          //console.log("Enable  button");
          jQuery('input.wpcf7-submit').prop('disabled', false);
        }
      }
    });
  }, 2000)

	// Sharing buttons popup
	$('.sharing-buttons').on('click', 'a', function(e) {
		var el = $(this);

		if (el.hasClass('popup')) {
			popupCenter(el.prop('href'), el.text(), 600, 450);
			e.preventDefault();
		}

		if (el.hasClass('print')) {
			if(window.print) window.print()
			e.preventDefault();
		}

	});

	// Masonry Layout
	masonryGalleries();

	// Lightbox for post images
	imageLightbox();

	amazonAssociatesWidget();

});
