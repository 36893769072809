// Setup hamburger menus
var hamburgerMenu = function() {
	var $ = jQuery;

	/*
		STAGE 1 - Hamburger button to open/close the menu
	*/

	// Hamburger button
	$('#hamburger-icon, #search-icon').on('click', function(e) {
		var html = $('html');
		var button = $('.caption', this);
		var menu_text = button.data('open-label') || button.text();
		var hide_text = button.data('close-label');
		var body_class = $(this).data('body-class');

		if (html.hasClass(body_class)) { // closing
			html.removeClass(body_class);

			if ($(this).is('#hamburger-icon'))
				html.removeClass('search-open');

			button.text(menu_text);
		} else { // opening
			html.addClass(body_class);

			button.data('open-label', menu_text);
			button.text(hide_text);
		}

		e.preventDefault();
	});

	/*
		STAGE 2 - Clickable collapsible menus for mobile
	*/

	// Select all menus that should be collapsible
	var collapsibleMenus = $('#main-navigation .top-nav');

	// Select the first level of links that have sub menus
	var topLevels = $('.nav > .menu-item-has-children', collapsibleMenus);

	topLevels
		.addClass('collapsible') // add collapsible CSS styling, doesn't apply on tablet and up
		.on('click', '> a', function(e) {

			// select the link's parent - the <li> element
			var li = $(this).parent();

			// if not mobile, stop here
			if (!currentBreakpoint.isMobile()) return;

			// if the clicked sub menu is already open
			if (li.hasClass('open')) {

				// close the sub menu
				li.removeClass('open');
				collapsibleMenus.removeClass('has-open');

			// if there is no sub menu open
			} else {

				// close any other menus so only one is open at a time
				topLevels.removeClass('open');

				// open the clicked menu
				li.addClass('open');
				collapsibleMenus.addClass('has-open');
			}

			e.preventDefault();
		}) // end click
		// for the currently open menu, open it by default
		.filter('.current_page_ancestor, .current-menu-item')
			.addClass('open')
			.parents(collapsibleMenus.selector).addClass('has-open');

	// Add extra link for top levels into sub menus
	$('> .sub-menu', topLevels).each(function() {
		var a = $(this).prev();
		$(this).prepend('<li class="menu-section-link"><a href="'+ a.prop('href') +'">'+ a.html() +' Home</a></li>');
	});
}

/*
Accessible drop down menus
Usage: accessibleMenu($('.nav'))
*/
var accessibleHoverMenus = function(menus) {
	var $ = jQuery;

	// Which menus should be made accessible
	var menus = $('.nav');

	// Blurs any open menus and removes the touch listener on the body
	function blurMenus() {
		// Hide any menus in focus
		$('.menu-item-has-children', menus).blur();
		// Remove touch handler
		$('body').off('touchend', blurMenus);
	}

	// the .hover class allows us to manage the behaviour better
	$('li', menus)
		.hover(function() {
			$(this).addClass('hover');
		}, function() {
			$(this).removeClass('hover');
		})
		// when menu links are touched
		.filter('.menu-item-has-children').find('> a').on('touchend', function(e) {

			// don't do this on mobile
			if (currentBreakpoint.isMobile()) return;

			// target element's <li>
			var li = $(this).parent();

			// If the link is not already been touched, prevent the click
			if (!li.hasClass('hover')) {
				e.preventDefault();
				e.stopPropagation();
			}

			// blur/hide any existing open menus
			blurMenus();

			// focusing the <a> will allow the menu to be displayed - see below
			$(this).focus();

		});

	// Set up keyboard-accessible and touch-accessible menu
	// .hover is added for each parent <li> when a link is focused
	$('a', menus)
		.focus(function() {
			$(this).parents('li').addClass('hover');
		})
		.blur(function() {
			$(this).parents('li').removeClass('hover');
		});
}; // end accessible menu
