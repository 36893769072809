// Amazon Associates Widget
var amazonAssociatesWidget = function() {

	var $ = jQuery;

	var widget = $('.amazon_associates_widget .widget-container');

	// Set default country to US
	var country = 'US';

	// we don't have a widget. no need to do anything further
	if (widget.length == 0) return;

	// build the widget
	var widgetSetup = function(country) {
		var aac = amazon_associates_code;

		if (country == 'GB') {
			var href  = aac.uk_href;
			var embed = aac.uk_embed;
		}
		else {
			var href  = aac.us_href;
			var embed = aac.us_embed;
		}

		$(function() {
			if (embed !== null)
				widget.prepend($.parseHTML(embed));

			if (href !== null)
				widget.find('.store-link').show().prop('href', href);
		});
	}

	// If country code is cached, load the widget.
	if ( country = localStorage.country ) {
		widgetSetup( country );
	}

	// Otherwise we need to get it
	else {
		// request the user's country code
		$.getJSON('https://ipinfo.io/').done( function(data) {

			// Request successful, set the country and cache it.
			if ( data.country ) {
				country = data.country;
				localStorage.setItem('country', country);
			}

		} ).fail( function(status) {

				// Leave the country variable as it is.
				console.warn('Couldn\'t look up IP address, loading default widget.');

		} ).always( function() {

			// Regardless of response, load the widget.
			widgetSetup(country);

		} );
	}
};
