var searchSuggest = (function() {

  var cachedQueries = {};

  function monitor(el) {
    var input       = $('input[type="search"]', el);
    var suggestions = $('+ div.suggestions', el);
    var query       = input.val();

    if (!query || query.length < 3) { // if no query, or query not long enough
      suggestions.hide().empty();

    } else if (cachedQueries[query]) { // if in cachedQueries

      suggestions.show(); // show the box if hidden
      suggestions.html(cachedQueries[query]);

    } else { // make request
      suggestions.show();

      // Create cache entry
      cachedQueries[query] = '';

      request = $.ajax(blogInfo('homeURL'), {
        data: {
          's':        query,
          'suggest':  '1'
        }
      }).done(function(data) {

        // cache the results of this query
        cachedQueries[query] = data;

        if (query == input.val()) {
          suggestions.html(data);
        }

      }).fail(function() {
        delete cachedQueries[query]; // Delete cache entry
      });
    }
  }

  return {
    init: function() {
      var forms = $('form.search-form');

      forms.on('input', function() {
        monitor(this);
      });
    },
    listQueries: function() { // dev use
      console.log(cachedQueries);
    },
    clearQueries: function() { // dev use
      for (var member in cachedQueries) delete cachedQueries[member];
    }
  }
})();

$(searchSuggest.init);
